export const SUBSCRIPTION_PACKAGES = [
    {
        title: 'Dane a účtovníctvo',
        key: 'taxes',
        moduleId: 'taxes',
        color: '#F2941A',
        saleBoxBackground: '#FAEFE1',
        icon: 'D',
        benefits: [
            '<span class="bold">100+</span> online školení z daní a účtovníctva',
            '<span class="bold">Odborné poradenstvo</span> s lektorom formou chatu',
            'Archív všetkých <span class="bold">videozáznamov</span>',
            '<span class="bold">Školiace materiály</span> na stiahnutie',
            '<span class="bold">10 % zľava</span> na konferencie a prezenčné školenia',
        ],
        price: '479,70',
        priceWithoutVat: '390',
    },
    {
        title: 'Mzdy a personalistika',
        key: 'wages',
        moduleId: 'wages',
        color: '#F00035',
        saleBoxBackground: '#F7DADA',
        icon: 'M',
        benefits: [
            '<span class="bold">100+</span> online školení z miezd a personalistiky',
            '<span class="bold">Odborné poradenstvo</span> s lektorom formou chatu',
            'Archív všetkých <span class="bold">videozáznamov</span>',
            '<span class="bold">Školiace materiály</span> na stiahnutie',
            '<span class="bold">10 % zľava</span> na konferencie a prezenčné školenia',
        ],
        price: '479,70',
        priceWithoutVat: '390',
    },
    {
        title: 'Verejná správa',
        key: 'government',
        moduleId: 'government',
        color: '#63BF4D',
        saleBoxBackground: '#E7F5E4',
        icon: 'V',
        benefits: [
            '<span class="bold">200+</span> online školení pre verejnú správu',
            '<span class="bold">Odborné poradenstvo</span> s lektorom formou chatu',
            'Archív všetkých <span class="bold">videozáznamov</span>',
            '<span class="bold">Školiace materiály</span> na stiahnutie',
            '<span class="bold">10 % zľava</span> na konferencie a prezenčné školenia',
        ],
        price: '479,70',
        priceWithoutVat: '390',
    },
];

export const SUBSCRIPTION_PACKAGES_KEYS = ['taxes', 'wages', 'government'];

export const SUBSCRIPTION_PACKAGES_KEYS_ENUM = {
    TAXES: 'taxes',
    WAGES: 'wages',
    GOVERNMENT: 'government',
};
